<!-- Localized -->
<template>
    <div class="flex flex-col gap-6 justify-between max-h-full flex-1 overflow-y-auto overflow-x-hidden">
        <Card showActionBtn :title="$t('pages.license.feature_list')" titleClass="text-white-text">
            <div class="flex flex-1 p-4 overflow-y-auto max-h-full h-full">
                <div v-if="loadingStates.fetchingDetails" class="flex flex-1 items-center justify-center py-14">
                    <Loader />
                </div>
                <List v-else :showTitle="false" :listItems="getFeaturedList" :showSearch="true" @handleFilterResult="handleFilterResult($event)">
                    <template #item="{ item }">
                        <FeatureListItem :item="item" />
                    </template>
                </List>
            </div>
        </Card>
    </div>
</template>

<script>
import Card from "@shared/components/card";
import List from "@shared/components/lists";
import FeatureListItem from "@shared/components/license-pack/feature-list";
import Loader from "@shared/loader";

export default {
    name: "feature-list",
    components: {
        Card,
        List,
        FeatureListItem,
        Loader,
    },
    props: {
        licenseData: {
            type: Object,
            default: () => {},
        },
        loadingStates: {
            type: Object,
            default: () => {},
        },
        getFeaturedList:{
            type:Array,
            default: () => []
        }
    },
    data() {
        return {};
    },
    computed: {},
    async mounted() {},
    methods: {
        handleFilterResult(event){
            this.$emit('handleFilterResult', {data:event, type:'features'})
        },
    },
};
</script>

<style lang="scss" scoped>
    .checkbox{
        display: none;
    }
</style>

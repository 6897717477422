<!-- Localized -->
<template>
    <div  class="items-center w-full px-2 grid grid-cols-2">
        <div class="flex gap-3 items-center">
            <input @change="$emit('userAssign', analyst)" :key="analyst.id" v-model="analyst.checked" type="checkbox" :id="analyst.id" class="checkbox cursor-pointer checkbox-primary checkbox-sm flex" />
            <label v-if="analyst.user_name" :for="analyst.id" class="cursor-pointer text-gray-700 font-semibold truncate">{{ analyst.user_name }}</label>

        </div>
    </div>
</template>

<script>

export default {
    name: "client-analyst-list",
    components: {},
    props: {
        analyst: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {};
    },
    computed: {},
    async mounted() {},
    methods: {
    },
};
</script>

<style lang="scss" scoped>
    input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
}
</style>

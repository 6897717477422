<!-- Localized -->
<template>
    <div class="flex flex-1 flex-col overflow-y-auto w-full">
    <SubHeader backRedirectionUrl="/license" :backButton="true" class="pr-1" :customCrumbLists="customBreadCrumbs" />
    <div class="flex space-x-6 overflow-y-auto py-6 rounded-lg h-full text-base-content-800">

        <div class="w-1/5 flex flex-col gap-6">
            <div class="w-full bg-card-bg self-start rounded-lg overflow-hidden divide-y line">
                <template v-for="section in getSections">
                    <div
                        v-if="section.show" 
                        :key="section.name"
                        :class="{
                            'bg-primary-300 text-white-text': getActiveSection.name === section.name,
                            'hover:bg-gray-100 hover:text-base-content-600': getActiveSection.name != section.name,
                            'bg-gray-100 border-b border-gray-300 text-gray-700 cursor-not-allowed pointer-events-none hover:bg-transparent hover:text-gray-700 opacity-70' : section.disabled
                        }"
                        class="py-2 px-4 cursor-pointer flex items-center"
                        @click="sectionClick(section)"
                    >
                        <div class="icon w-7" v-if="section.icon">
                            <component :is="section.icon" class="fill-current" />
                        </div> {{section.label}}
                    </div>

                </template>

            </div>

        </div>

        <div class="flex-1 overflow-y-auto max-h-full min-h-full scroll-bar pr-2" v-if="getActiveSection">
            <!-- <KeepAlive> -->
            <component
                @handleAddNewTenant="handleAddNewTenant"
                :licenseData="licenseData"
                :ref="getActiveSection.name"
                :loadingStates="loadingStates"
                @handleFormSubmit="handleFormSubmit($event)"
                :getFeaturedList="getFeaturedList"
                @selectAllFeatures="selectAllFeatures($event)"
                @licensePackEvent="licensePackEvent($event)"
                @handleFilterResult="handleFilterResult($event)"
                @tenantAssign="tenantAssign($event)"
                @userAssign="userAssign($event)"
                @getTenantUserData="getTenantUserData($event)"
                @assignTenantUser="assignTenantUser($event)"
                :getClientsList="getClientsList"
                :getUserList="getUserList"
                @selectAllClients="selectAllClients($event)"
                @selectAllUsers="selectAllUsers($event)"
                @licenseReadOnlyPermission="licenseReadOnlyPermission($event)"
                v-bind:is="getActiveSection.show && getActiveSection.component"
                :headerList="headerList"
                :userHeaderList="userHeaderList"
                :disabledBUtton="disabledBUtton"
           />
            <!-- </KeepAlive> -->
        </div>

        <ModalConfirm :title="$t('general.are_you_sure')" :message="$t('pages.license.confirm_delete_field')" ref="confirm-popup">
        </ModalConfirm>
    </div>
</div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import Button from "@/components/button";
import Loader from "@shared/loader";
import { getLicenseDetails, getFeature, getAssignedFeature, getAllClients, allotClients, getAllTenantUsers, allocateUser } from "../services" 
import { LicenseInfo, LecenseFeatures, AssignClientCard, AssignUserCard } from "../components/index";
import { assignedTenantIcon, featureListIcon, licenseDetailsIcon } from "@shared/icons.js";
const ModalConfirm = () => import("@shared/modal-confirm");
// End
export default {
    name: "licence-details",
        components: {
            SubHeader,
            Button,
            Loader,
            ModalConfirm,
        },
        data: () => ({
            sections: [
                {
                    name: "license-details",
                    label_key: 'pages.license.license_details',
                    disabled: false,
                    component: LicenseInfo,
                    show: true,
                    icon: licenseDetailsIcon,
                },
                {
                    name: "license-features",
                    label_key: 'pages.license.license_features',
                    disabled: true,
                    component: LecenseFeatures,
                    show: true,
                    icon: featureListIcon,
                },
                {
                    name: "license-client",
                    label_key: 'pages.license.assigned_client',
                    disabled: true,
                    component: AssignClientCard,
                    show: true,
                    icon: assignedTenantIcon,
                },
                {
                    name: "license-user",
                    label_key: 'pages.license.assigned_users',
                    disabled: true,
                    component: AssignUserCard,
                    show: true,
                    icon: assignedTenantIcon,
                },
            ],
            activeSection: null,
            loadingStates: {
                fetchingDetails: false,
                submit: false,
                delete: false,
            },
            customBreadCrumbs: [],
            licenseData: {
                licenseDetails: {
                    license_pack_name: null,
                    validity: null,
                    description: null,
                    alloted_license_count: null,
                    balanced_license_count: null,
                    tenant_license_count: null,
                },
                featuresLists: [],
                licenseClients: [],
                licenseUsers: [],
            },
            serchValues: {
                features: "",
                clients: "",
                users: "",
            },
            clientsListsHeader: [{ 
                key: 'pages.license.tenant_client'
            }, { 
                key: 'pages.license.no_of_license'
            }],
            usersListsHeader: [{ 
                key: 'pages.license.tenant_users'
            }],
            tenant_id: '',
            disabledBUtton: true,
        }),
        computed: {
            getActiveSection() {
                return this.activeSection || this.getSections[0];
            },
            getSections: {
                get() {
                    return this.sections?.map((sec) => ({
                        ...sec,
                        label: this.$t(sec.label_key),
                    })) || [];
                },
                set(val) {
                    this.sections = val;
                },
            },
            getFeaturedList() {
                return this.licenseData.featuresLists?.filter((el) => el.name?.toLowerCase()?.includes(this.serchValues.features.toLowerCase()));
            },
            getClientsList() {
                return this.licenseData.licenseClients?.filter((el) => el.name?.toLowerCase()?.includes(this.serchValues.clients.toLowerCase()));
            },
            getUserList() {
                return this.licenseData.licenseUsers?.filter((el) => el.user_name?.toLowerCase()?.includes(this.serchValues.users.toLowerCase()));
            },
            headerList() {
                return this.clientsListsHeader.map((head) => ({
                    ...head,
                    name: this.$t(head.key),
                }))
            },
            userHeaderList() {
                return this.usersListsHeader.map((head) => ({
                    ...head,
                    name: this.$t(head.key),
                }))
            }
        },
        watch: {
            $route() {
                this.updateList();
            },
        },
        async mounted() {
            this.updateList();
            this.checkActiveTab();
        },
        methods:{ 
            updateList() {
                if (this.$route.name === "license-details") {
                    this.getSections = this.getSections.map((el) => ({ ...el, disabled: false }));
                }
            },
            handleAddNewTenant() {
                this.licenseData.assignedClients.push({
                    license_count: 1,
                    id: null,
                    tenant_id: null,
                    title: "",
                });
            },
            async handleFormSubmit({ data, section }) {
                console.log(data, section, "seasdsad");
                this.loadingStates.submit = true;
                try {
                    let payload = {
                        license_pack_id: this.licenseId,
                        clients: this.licenseData.licenseClients.filter((el) => el.checked).map((tenant) => ({ client_id: tenant.id, license_count: tenant.license_count })),
                    };
                    let response = await allotClients(payload);
                    this.$toast.success(response?.data ? response?.data?.message : this.$t('pages.license.success_client_assigned'));
                    this.loadingStates.submit = false;
                } catch (error) {
                    if (Array.isArray(error?.response?.data?.detail)) {
                        this.$toast.error(error?.response?.data?.detail?.[0]?.msg || this.$t('general.default_error')) ;
                    } else {
                        this.$toast.error(error?.response?.data?.detail || this.$t('general.default_error')) ;
                    }
                    this.loadingStates.submit = false;
                }
            
            },
            async assignTenantUser() {
                this.disabledBUtton = false;
                this.loadingStates.submit = true;
                try {
                    let payload = {
                        license_pack_id: this.licenseId,
                        users: this.licenseData.licenseUsers.filter((el) => el.checked).map((tenant) => (tenant.id)),
                    };
                    let { data } = await allocateUser(payload);
                    this.$toast.success(data ? this.$t('pages.license.success_license_assigned_to_users'):data?.message) ;
                    this.loadingStates.submit = false;
                } catch (error) {
                    if (Array.isArray(error?.response?.data?.detail)) {
                        this.$toast.error(error?.response?.data?.detail?.[0]?.msg || this.$t('general.default_error')) ;
                    } else {
                        this.$toast.error(error?.response?.data?.detail || this.$t('general.default_error')) ;
                    }
                    this.loadingStates.submit = false;
                }
            },
            async getAPIDetails(section_name) {
                let apiMapper = {
                    "license-details": this.fetchLicensePackDetails,
                    "license-features": this.featureData,
                    "license-client": this.getClientssData,
                    "license-user": this.getTenantUserData,
                };
                await apiMapper[section_name]();
            },
            async sectionClick(section) {
                if (section.name === this.activeSection.name) return;
                this.activeSection = section;
                this.$router.push({ params: { tab: section.name } });
                this.customBreadCrumbs = [{ name: section.label }];
                await this.getAPIDetails(section.name);
                this.setBredCrumbs();
            },
            setBredCrumbs(crumb) {
                this.customBreadCrumbs = [];

                this.customBreadCrumbs = [{ name: this.activeSection.label }];
                if (crumb) {
                    this.customBreadCrumbs.push({ name: crumb });
                }
            },
            async checkActiveTab() {
                let active_tab = this.$route.params.tab;
                let query_data = this.$route.query;
                if (this.$route.name === "license-add") {
                    active_tab = "license-details";
                }
                active_tab ? this.$router.push({ params: { tab: active_tab }, query: query_data }) : this.$router.push({ params: { tab: "license-details" }, query: query_data });
                let tabData = this.getSections.find((el) => el.name === active_tab);
                this.activeSection = tabData ? tabData : this.getSections[0];
                if (this.$route.name === "license-details" && this.$route.params.id) {
                    this.licenseId = this.$route.params.id;
                    await this.getAPIDetails(this.activeSection.name);
                }
                this.setBredCrumbs();
            },
            
            async fetchLicensePackDetails() {
                this.loadingStates.fetchingDetails = true;
                try {
                    let { data } = await getLicenseDetails(this.$route.params.id);
                    this.licenseData.licenseDetails = data[0];
                } catch (error) {
                    if (Array.isArray(error?.response?.data?.detail)) {
                        this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
                    } else {
                        this.$toast.error(error?.response?.data?.detail);
                    }
                    console.log(error, "errror");
                }
                this.loadingStates.fetchingDetails = false;
            },
            handleFilterResult({ data, type }) {
                this.serchValues[type] = data;
            },
            async featureData() {
                this.loadingStates.fetchingDetails = true;
                this.serchValues.features = "";
                try {
                    
                    let feature = await getFeature()
                    let featureLists = feature.data.features
                    let { data } = await getAssignedFeature(this.$route.params.id);
                    let assignedFeature =  data.feature
                    let LicenceFeature = []
                    assignedFeature.forEach((res) => {
                        let assFeat = featureLists.find((data) => data.id == res.id)
                        assFeat.read_only = res.read_only
                        LicenceFeature.push(assFeat);
                    })
                    this.licenseData.featuresLists = LicenceFeature
                } catch (error) {
                    if (Array.isArray(error?.response?.data?.detail)) {
                        this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
                    } else {
                        this.$toast.error(error?.response?.data?.detail);
                    }
                }
                this.loadingStates.fetchingDetails = false;
            },
            async getClientssData() {
                this.loadingStates.fetchingDetails = true;
                try {
                    let { data } = await getLicenseDetails(this.$route.params.id);
                    let assignedClients = data[0].clients;
                    this.tenant_id = this.$store.getters.getTenantId;
                    let clientLists = await getAllClients(this.tenant_id)
                    clientLists =  clientLists.data
                    clientLists = clientLists?.map((tenant) => {
                        for (let i of assignedClients) {
                            if (i.client_id === tenant.id) {
                                return { ...tenant, checked: true, license_count: i.license_count };
                            }
                        }
                        return { ...tenant, checked: false, license_count: null };
                    });
                this.licenseData.licenseClients = clientLists;

                } catch (error) {
                    if (Array.isArray(error?.response?.data?.detail)) {
                        this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
                    } else {
                        this.$toast.error(error?.response?.data?.detail);
                    }
                    console.log(error, "errror");
                }
                this.loadingStates.fetchingDetails = false;
            },
            async getTenantUserData() {
                this.loadingStates.fetchingDetails = true;
                try {
                    let { data } = await getLicenseDetails(this.$route.params.id);
                    let assignedUsers = data[0].tenant_users || [];
                    this.tenant_id = this.$store.getters.getTenantId;
                    let userLists = await getAllTenantUsers(this.tenant_id)
                    userLists =  userLists.data
                    userLists = userLists?.map((tenant) => {
                        for (let i of assignedUsers) {
                            if (i.user_id === tenant.id) {
                                return { ...tenant, checked: true };
                            }
                        }
                        return { ...tenant, checked: false };
                    });
                this.licenseData.licenseUsers = userLists;

                } catch (error) {
                    if (Array.isArray(error?.response?.data?.detail)) {
                        this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
                    } else {
                        this.$toast.error(error?.response?.data?.detail);
                    }
                }
                this.loadingStates.fetchingDetails = false;
            },
            tenantAssign(event) {
                this.disabledBUtton = false;
                this.licenseData.licenseClients = this.licenseData.licenseClients.map((el) => {
                    if (el.id === event.id && !event.checked) {
                        return { ...el, license_count: null };
                    } else if (el.id === event.id && event.checked) {
                        return { ...el, license_count: event.license_count };
                    }else {
                        return { ...el };
                    }
                });
            },
            userAssign(event) {
                this.disabledBUtton = false;
                this.licenseData.licenseUsers = this.licenseData.licenseUsers.map((el) => {
                    if (el.id === event.id && !event.checked) {
                        return { ...el, license_count: null };
                    } else if (el.id === event.id && event.checked) {
                        return { ...el, license_count: 1 };
                    } else {
                        return { ...el };
                    }
                });
            },
            selectAllClients(event) {
                this.disabledBUtton =  false;
                if (event) {
                    this.licenseData.licenseClients = this.licenseData.licenseClients.map((el) => ({ ...el, checked: event, license_count: el.license_count || 1 }));
                } else {
                    this.licenseData.licenseClients = this.licenseData.licenseClients.map((el) => ({ ...el, checked: event, license_count: null }));
                }
            },
            selectAllUsers(event) {
                this.disabledBUtton =  false;
                if (event) {
                    this.licenseData.licenseUsers = this.licenseData.licenseUsers.map((el) => ({ ...el, checked: event }));
                } else {
                    this.licenseData.licenseUsers = this.licenseData.licenseUsers.map((el) => ({ ...el, checked: event }));
                }
            }
        },
    };
</script>
<style scoped lang="scss">

</style>
<!-- Localized -->
<template>
    <div class="flex flex-1 items-center justify-between gap-6">
        <div class="flex gap-3 items-center">
            <div class="flex flex-col gap-1 text-gray-500 tracking-wide font-semibold">
                <div class="flex gap-4 items-center">
                    <label :for="item.name" class="pl-4 text-gray-700 font-semibold truncate">{{ item.name }}</label>
                    <div v-if="item.read_only" class="bg-yellow-500 font-semibold text-sm px-1 rounded-lg w-auto text-white">
                        {{ $t('components.license_pack.ro') }}
                    </div>
                </div>
                <span class="text-xs pl-4">{{ item.type }}</span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "feature-list",
    components: {
    },
    props: {
        item:{
            type:Object,
            default: () => {}
        }
    },
    data() {
        return {
        };
    },
    computed: {},
    async mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
